import { capital } from 'case';
import { Box, Typography, List, ListItem, ListItemText, Button } from '@mui/material';
import { ExternalLink, Loader, Card } from '../../components';
import { useQuery } from 'react-query';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { IResponse, IRecentItem, IUser, IOfficeDetail } from '../../models';
import { createSuperAdminAuditOfficeLog, getOffice, getRecentItems } from '../../fetch';
import { getCustomerDetailRoute, getLegacyUrl, OFFICE_EMULATE_DATA } from '../../helpers';
import { useContext, useState } from 'react';
import { UserContext } from '../../context';
import { useHistory } from 'react-router-dom';
import { useConfirm } from '../../hooks';
import { useSnackbar } from 'notistack';
import { BrandingContext } from '../../context/branding-context';
import { Paths } from '../../constants';

export const RecentItems = () => {
  const { isLoading: isLoadingRecentItems, data: recentItemsData } = useQuery<
    IResponse<IRecentItem[]>,
    Error
  >(['recentItems'], () => getRecentItems({ sortDirection: 'Desc', perPage: 10 }), {
    staleTime: Infinity,
  });
  const items: IRecentItem[] | null | undefined = recentItemsData?.records ?? null;
  const hasRecentItems = items && items.length > 0 ? true : false;
  const legacyUrl = getLegacyUrl?.();
  const history = useHistory();

  const { v2Customers, v2Services, v2Scheduling } = useFlags();
  const { isSuperAdmin, user, setUser } = useContext(UserContext);

  const { isPoolService } = useContext(BrandingContext);
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const ldClient = useLDClient();
  const [isLoadingEmulation, setIsLoadingEmulation] = useState(false);

  const changeEmulation = async (officeId: string, officeCode: string, url: string) => {
    const confirmText = 'Are you sure you want to emulate office';
    const result = await confirm(
      !!officeCode ? `${confirmText} ${officeCode}?` : `${confirmText}?`
    );
    if (result) {
      try {
        setIsLoadingEmulation(true);
        const office: IOfficeDetail = await getOffice(officeId);
        const officeStorageData = {
          officeId: office.officeId,
          storeId: office.storeId,
          code: office.code,
          officeName: office.officeName,
          inactive: office.inactive,
          adminEmail: office.adminEmail,
          address: office.address,
        };
        await createSuperAdminAuditOfficeLog({
          userId: user?.userId ?? '',
          officeId: officeId,
        });
        const newUser: IUser = {
          ...user!,
          officeId: officeId,
          officeCode: officeStorageData.code,
          userType: 'Emulating',
        };
        setUser(newUser);
        await ldClient?.identify({
          key: newUser?.loginName?.toLowerCase(),
          custom: {
            store: officeStorageData.officeName ?? '',
            storeCode: officeStorageData.code ?? '',
            userName: newUser?.userName ?? '',
            storeId: officeId ?? '',
            userType: newUser?.userType ?? '',
            isOfficeAdmin: newUser?.isOfficeAdmin ?? false,
          },
        });
        sessionStorage.setItem(
          OFFICE_EMULATE_DATA,
          JSON.stringify({
            ...officeStorageData,
            userId: user?.userId,
            isPoolService,
          })
        );
        enqueueSnackbar(`Success, now emulating office ${office.code}`, {
          variant: 'success',
        });
        history.push(url);
      } catch (e: any) {
        enqueueSnackbar(e?.Detail ?? `Error emulating office, please try again.`, {
          variant: 'error',
        });
      } finally {
        setIsLoadingEmulation(false);
      }
    }
  };

  const handleRecentItemClick = async (
    url: string,
    officeId: string | null,
    officeCode: string | null
  ) => {
    if (!!officeId && officeId !== user?.officeId) {
      await changeEmulation(officeId, officeCode ?? '', url);
    } else {
      history.push(url);
    }
  };

  return (
    <Card
      cardTitleProps={{
        title: 'Recent Customers',
      }}
      sxProps={{ height: '100%' }}
    >
      {isLoadingRecentItems && (
        <Box
          display="flex"
          flexDirection="column"
          height="13rem"
          alignItems="center"
          justifyContent="center"
        >
          <Loader position="centered" />
        </Box>
      )}
      {isLoadingEmulation && <Loader position="centered" type="fullscreen" />}
      {hasRecentItems && (
        <>
          <List sx={{ padding: 0, marginTop: '-0.75rem', marginBottom: '-.65rem' }}>
            {items?.map((item: IRecentItem, index) => {
              const label = capital(item.description);
              const office =
                isSuperAdmin && !!item?.officeCode ? (
                  <Typography variant="caption" color="grey">
                    {' '}
                    - {item.officeCode}
                  </Typography>
                ) : (
                  ''
                );

              return (
                <ListItem sx={{ padding: 0 }} key={`${index}`}>
                  <ListItemText sx={{ margin: 0 }}>
                    {item.recordType === 'Service' && (
                      <>
                        {v2Services ? (
                          <Button
                            variant="text"
                            sx={{
                              fontSize: 14,
                              padding: 0,
                              '&:hover': {
                                backgroundColor: 'transparent',
                                color: theme => theme.palette.common.black,
                              },
                            }}
                            onClick={() =>
                              handleRecentItemClick(
                                `${Paths.maintenance.url}/${item.recordId}`,
                                item.officeId,
                                item.officeCode
                              )
                            }
                          >
                            {label}
                          </Button>
                        ) : (
                          <ExternalLink
                            sx={{ fontSize: 14 }}
                            target="_blank"
                            to={`${legacyUrl}/Office/ScheduledService`}
                          >
                            {label}
                          </ExternalLink>
                        )}
                        {office}
                      </>
                    )}
                    {item.recordType === 'Account' && (
                      <>
                        {v2Customers ? (
                          <Button
                            variant="text"
                            sx={{
                              fontSize: 14,
                              padding: 0,
                              justifyContent: 'flex-start',
                              '&:hover': {
                                backgroundColor: 'transparent',
                                color: theme => theme.palette.common.black,
                              },
                            }}
                            onClick={() =>
                              handleRecentItemClick(
                                `${getCustomerDetailRoute(item.recordId)}`,
                                item.officeId,
                                item.officeCode
                              )
                            }
                          >
                            {label}
                          </Button>
                        ) : (
                          <ExternalLink
                            target="_blank"
                            to={`${legacyUrl}/Office/${item.recordType}/View/${item.recordId}`}
                            sx={{ fontSize: 14 }}
                          >
                            {label}
                          </ExternalLink>
                        )}
                        {office}
                      </>
                    )}
                    {item.recordType === 'Task' && (
                      <>
                        {v2Scheduling ? (
                          <Button
                            variant="text"
                            sx={{
                              fontSize: 14,
                              padding: 0,
                              '&:hover': {
                                backgroundColor: 'transparent',
                                color: theme => theme.palette.common.black,
                              },
                            }}
                            onClick={() =>
                              handleRecentItemClick(
                                `${Paths.tasks.url}/${item.recordId}`,
                                item.officeId,
                                item.officeCode
                              )
                            }
                          >
                            {label}
                          </Button>
                        ) : (
                          <ExternalLink
                            sx={{ fontSize: 14 }}
                            target="_blank"
                            to={`${legacyUrl}/Office/ScheduledTask`}
                          >
                            {label}
                          </ExternalLink>
                        )}
                        {office}
                      </>
                    )}
                  </ListItemText>
                </ListItem>
              );
            })}
          </List>
        </>
      )}
      {!hasRecentItems && !isLoadingRecentItems && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="13.9rem"
        >
          <Typography>No Recent Items</Typography>
        </Box>
      )}
    </Card>
  );
};
