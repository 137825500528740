import {
  DataGrid as MuiDataGrid,
  DataGridProps as MuiDataGridProps,
  GridCallbackDetails,
  GridSortModel,
  GridPaginationModel,
} from '@mui/x-data-grid';
import { Pagination, Typography, useMediaQuery, Box, TablePagination, styled } from '@mui/material';
import { DataGridPagination } from './Pagination';
import { FC } from 'react';
import { Loader } from '../loader';
import { MobileTable } from '../table/mobile-table';
import clsx from 'clsx';

export const DataGrid: FC<MuiDataGridProps> = props => {
  return <MuiDataGrid disableColumnSelector {...props} />;
};

export interface ServerSideDataGridProps
  extends Omit<
    MuiDataGridProps,
    | 'sortingMode'
    | 'paginationMode'
    | 'rowCount'
    | 'onPageChange'
    | 'onSortModelChange'
    | 'page'
    | 'pageSize'
    | 'onPageSizeChange'
  > {
  rowCount: number;
  onPageChange: (page: number) => void;
  onSortModelChange?: (model: GridSortModel, details: GridCallbackDetails<any>) => void;
  page: number;
  pageSize: number;
  onPageSizeChange: (pageSize: number) => void;
  mobileBreakPoint?: number;
  hasMobileLayout?: boolean;
  isKeysetTable?: boolean;
  mobileProps?: {
    useFirstCell?: boolean;
    handleView?: (val: any) => void;
    handleEdit?: (val: any) => void;
    handleDelete?: (val: any) => void;
    handleDownload?: (val: any) => void;
    handleAccordionLabel?: (val: any) => void;
    handleRefresh?: (val: any) => void;
    mobileDefaultAccessor?: string;
    mobileCustomDefaultAccessor?: (val: any) => void;
    truncateAccordionLabel?: boolean;
    type?: string;
    showHandleActions?: boolean;
    stackAccordionDetails?: boolean;
  };
  noResultsMessage?: string;
  stickyActionsColumn?: boolean;
  disableColumnMenu?: boolean;
}

export interface StyledDataGridProps {
  stickyActionsColumn?: boolean;
}

export const ServerSideDataGrid = ({
  page,
  pageSize,
  onPageSizeChange,
  onPageChange,
  noResultsMessage,
  isKeysetTable,
  stickyActionsColumn = false,
  disableColumnMenu = true,
  ...props
}: ServerSideDataGridProps) => {
  const isDesktop = useMediaQuery(`(min-width: ${props?.mobileBreakPoint || '960'}px)`);
  const onPaginationModelChange = (model: GridPaginationModel) => {
    onPageChange?.(model.page);
    onPageSizeChange?.(model.pageSize);
  };
  if (!isDesktop && props.hasMobileLayout) {
    return (
      <MobileGridWrapper>
        {props.loading && <Loader type="overlay" position="centered" />}
        {props?.rows?.length === 0 && (
          <Box display="flex" alignItems="center" justifyContent="center" height="100%">
            <Typography variant="body1" textAlign="center" mt={1}>
              {noResultsMessage ?? 'There are no results to display.'}
            </Typography>
          </Box>
        )}
        {props?.rows.map((row: any, index) => (
          <MobileTable
            key={`${index}`}
            fields={
              props?.mobileProps?.showHandleActions
                ? props.columns
                    .map(col => {
                      if (!col.headerName && col.field !== 'actions') {
                        return null;
                      }
                      return col;
                    })
                    .filter(Boolean)
                : (props.columns.filter(col => col.headerName) as any)
            }
            row={row}
            {...props.mobileProps}
            {...props}
          />
        ))}
        {props.rows?.length > 0 && !props.hideFooter && (
          <Box mt={1} display="flex" justifyContent="center">
            {isKeysetTable ? (
              <TablePagination
                component="div"
                className={classes.mobilePaginationKeyset}
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                count={props.rowCount}
                page={page}
                onPageChange={(_e, newPage) => onPageChange(newPage)}
                rowsPerPage={pageSize}
                onRowsPerPageChange={e => onPageSizeChange(+e.target.value)}
                labelRowsPerPage={'Showing:'}
                showFirstButton={isKeysetTable}
                showLastButton={isKeysetTable}
              />
            ) : (
              <Pagination
                color="primary"
                count={Math.ceil(props.rowCount / pageSize)}
                page={page + 1}
                onChange={(_e, value) => onPageChange?.(value - 1)}
              />
            )}
          </Box>
        )}
      </MobileGridWrapper>
    );
  }
  return (
    <>
      {/* force component to update if the rowCount changes */}
      <GridWrapper
        key={props.rowCount}
        style={stickyActionsColumn ? { width: '100%', position: 'relative' } : undefined}
        stickyActionsColumn={stickyActionsColumn}
      >
        <DataGrid
          className={clsx(classes.dataGrid, props.className)}
          disableRowSelectionOnClick
          filterMode="server"
          sortingMode="server"
          paginationMode="server"
          paginationModel={{ page, pageSize }}
          onPaginationModelChange={onPaginationModelChange}
          sortingOrder={['asc', 'desc']}
          disableColumnMenu={disableColumnMenu}
          slotProps={
            isKeysetTable
              ? {
                  pagination: {
                    showFirstButton: true,
                    showLastButton: true,
                    labelRowsPerPage: 'Showing:',
                    rowsPerPageOptions: [5, 10, 25, 50, 100],
                  },
                }
              : undefined
          }
          slots={{
            pagination: isKeysetTable
              ? undefined
              : () => {
                  return <DataGridPagination />;
                },
            noRowsOverlay: () => {
              return (
                <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                  <Typography variant="body1" textAlign="center" margin={0}>
                    {noResultsMessage ?? 'There are no results to display.'}
                  </Typography>
                </Box>
              );
            },
          }}
          rowHeight={35}
          {...props}
        />
      </GridWrapper>
    </>
  );
};

const PREFIX = 'DataGrid';

const classes = {
  mobilePaginationKeyset: `${PREFIX}-mobilePaginationKeyset`,
  dataGrid: `${PREFIX}-dataGrid`,
};

const MobileGridWrapper = styled(Box)<StyledDataGridProps>(() => ({
  [`& .${classes.mobilePaginationKeyset}`]: {
    '& .MuiTablePagination-toolbar': {
      flexWrap: 'wrap',
      justifyContent: 'center',
      paddingLeft: 0,
    },
    '& .MuiTablePagination-selectLabel': {
      marginBottom: 0,
    },
    '& .MuiTablePagination-displayedRows': {
      marginBottom: 0,
    },
  },
}));

const GridWrapper = styled('div', {
  shouldForwardProp: prop => prop !== 'stickyActionsColumn',
})<StyledDataGridProps>(({ theme, stickyActionsColumn }) => ({
  [`& .${classes.dataGrid}`]: {
    background: theme.palette.background.paper,
    border: 'none',
    '& .MuiDataGrid-main': {
      overflow: stickyActionsColumn ? 'unset !important' : undefined,
    },
    '& .MuiDataGrid-columnHeaders': {
      border: 'none',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontSize: theme.typography.body1.fontSize,
      fontWeight: 'bold',
      color: theme.palette.typography.tableHeaders,
    },
    '& .MuiDataGrid-columnSeparator': {
      opacity: '0 !important',
    },
    '& .MuiDataGrid-cell': {
      fontSize: theme.typography.body2.fontSize,
      fontWeight: 'bold',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      color: theme.palette.text.primary,
    },
    '& .MuiTablePagination-selectLabel': {
      marginBottom: 0,
    },
    '& .MuiTablePagination-displayedRows': {
      marginBottom: 0,
    },
    '& .col-sticky': {
      position: 'sticky',
      right: 0,
      backgroundColor: theme.palette.background.paper,
      boxShadow: 'rgba(0, 0, 0, 0.21) -2px 0px 4px -2px',
      zIndex: 1,
    },
    '& .MuiDataGrid-row:hover .col-sticky': {
      backgroundColor: theme.palette.grey[100],
    },
  },
}));
