import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Box, IconButton, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import {
  CardTitle,
  FilterForm,
  GridDataFetcher,
  useDataGrid,
  useFilters,
  ServerSideDataGrid,
  CardFiltersLayout,
  Loader,
  Card,
} from '../../../components';
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid';
import { IFilterLayout, IOffice, IOfficeDetail, IUser } from '../../../models';
import { formatAddressSingleLine, OFFICE_EMULATE_DATA } from '../../../helpers';
import {
  getOfficesFilters,
  getOffices,
  getOffice,
  createSuperAdminAuditOfficeLog,
} from '../../../fetch';
import { faFilter, faFilterCircleXmark, faEdit, faEye } from '@fortawesome/free-solid-svg-icons';
import { EditStoreModal } from './edit-office-modal';
import { SearchParamsContext, UserContext } from '../../../context';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useConfirm } from '../../../hooks';
import { useHistory } from 'react-router-dom';
import { BrandingContext } from '../../../context/branding-context';
import { Paths } from '../../../constants';

const filtersLayout: Record<string, IFilterLayout> = {
  Code: {
    sortOrder: 0,
    xs: 12,
    md: 4,
  },
  Name: {
    sortOrder: 1,
    xs: 12,
    md: 4,
  },
  Inactive: {
    sortOrder: 2,
    xs: 12,
    sm: 4,
  },
};
export const OfficeManagementTable: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { setUser, user, updatePaymentProcessorData } = useContext(UserContext);
  const { isPoolService } = useContext(BrandingContext);
  const { queryParams } = useContext(SearchParamsContext);

  const [isShowingModal, showModal] = useState(false);
  const [isLoadingStore, setIsLoadingStore] = useState(false);
  const [isLoadingEmulation, setIsLoadingEmulation] = useState(false);
  const [currentOffice, setCurrentOffice] = useState<IOfficeDetail | null>(null);
  const ldClient = useLDClient();
  const confirm = useConfirm();

  const {
    isShowingFilters,
    filtersInitialized,
    appliedFilters,
    filters,
    filterValues,
    onSubmit: onSubmitFilters,
    onFilterToggle,
    onChange: onFiltersChange,
    onReset,
    isLoading: isLoadingFilters,
  } = useFilters({
    filterFetcher: useCallback(() => getOfficesFilters(), []),
    shouldUseSearchParams: isPoolService,
  });

  const dataFetcher: GridDataFetcher<IOffice> = useCallback(
    async ({ perPage, sortColumn, sortDirection, page, searchParams }) => {
      if (!filtersInitialized) {
        return {
          continueLoading: true,
        };
      }

      // don't call the api when this query param is present or on services page
      if (queryParams && queryParams.includes('redirect')) {
        return {
          continueLoading: true,
        };
      }

      try {
        const res = await getOffices({
          sortBy: sortColumn || 'code',
          sortDirection: sortDirection || 'desc',
          perPage,
          page: page + 1,
          ...appliedFilters,
        });

        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error) {
        enqueueSnackbar(`Error loading offices, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filtersInitialized, appliedFilters]
  );

  const {
    rows,
    isLoading,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
  } = useDataGrid<IOffice>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'office-management-grid',
      sortColumn: 'code',
      sortDirection: 'desc',
    },
    dataFetcher,
    shouldUseSearchParams: isPoolService,
  });

  const handleEdit = async (office: IOffice) => {
    try {
      setIsLoadingStore(true);
      const res = await getOffice(office.officeId);
      setCurrentOffice(res);
      showModal(true);
    } catch (e: any) {
      enqueueSnackbar(e?.Detail ?? `Error loading office, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsLoadingStore(false);
    }
  };

  const handleEmulation = async (office: IOffice) => {
    const result = await confirm(`Are you sure you want to emulate office ${office.officeName}?`);
    if (result) {
      try {
        setIsLoadingEmulation(true);
        await createSuperAdminAuditOfficeLog({
          userId: user?.userId ?? '',
          officeId: office.officeId,
        });
        const newUser: IUser = {
          ...user!,
          officeId: office.officeId,
          officeCode: office.code,
          userType: 'Emulating',
        };
        setUser(newUser);
        await ldClient?.identify({
          key: newUser?.loginName?.toLowerCase(),
          custom: {
            store: office?.officeName ?? '',
            storeCode: office.code ?? '',
            userName: newUser?.userName ?? '',
            storeId: office.officeId ?? '',
            userType: newUser?.userType ?? '',
            isOfficeAdmin: newUser?.isOfficeAdmin ?? false,
          },
        });
        await updatePaymentProcessorData();
        sessionStorage.setItem(
          OFFICE_EMULATE_DATA,
          JSON.stringify({
            ...office,
            userId: user?.userId,
            isPoolService,
          })
        );
        enqueueSnackbar(`Success, now emulating office ${office.officeName}`, {
          variant: 'success',
        });
        setTimeout(() => {
          history.push('/');
          setIsLoadingEmulation(false);
        }, 500);
      } catch (e: any) {
        enqueueSnackbar(e?.Detail ?? `Error emulating office, please try again.`, {
          variant: 'error',
        });
      }
    }
  };

  const columns = useMemo((): GridColDef[] => {
    return [
      {
        field: 'code',
        headerName: 'Office Code',
        type: 'string',
        maxWidth: 150,
        minWidth: 100,
        flex: 1,
        disableColumnMenu: true,
        filterable: false,
      },
      {
        field: 'officeName',
        headerName: 'Office Name',
        type: 'string',
        flex: 1,
        disableColumnMenu: true,
        filterable: false,
      },
      {
        field: 'adminEmail',
        headerName: 'Admin Email',
        type: 'string',
        flex: 1.5,
        disableColumnMenu: true,
        filterable: false,
      },
      {
        field: 'address',
        headerName: 'Address',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        type: 'string',
        flex: 2,
        renderCell: (params: GridRenderCellParams<IOffice>) => {
          const { address } = params.row;
          return (
            <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {formatAddressSingleLine(
                address?.street ?? '',
                address?.city ?? '',
                address?.state ?? '',
                address?.postalCode ?? ''
              )}
            </Box>
          );
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        minWidth: 80,
        maxWidth: 80,
        flex: 1,
        renderCell: (params: GridRenderCellParams<IOffice>) => {
          return <>{params.row.inactive ? 'Inactive' : 'Active'}</>;
        },
        disableColumnMenu: true,
        filterable: false,
      },
      {
        field: 'actions',
        headerName: '',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        align: 'center',
        renderCell: (params: GridRenderCellParams<IOffice>) => {
          return (
            <Box display="flex">
              {isPoolService ? (
                <IconButton
                  color="primary"
                  title="Edit Office"
                  onClick={() => {
                    history.push(
                      `${Paths.officeManagement.url}/${params.row.officeId}${
                        queryParams
                          ? `?redirect=${encodeURIComponent(
                              `${Paths.officeManagement.url}?${queryParams}`
                            )}`
                          : ''
                      }`
                    );
                  }}
                >
                  <FontAwesomeIcon icon={faEdit} size="sm" />
                </IconButton>
              ) : (
                <IconButton
                  color="primary"
                  title="Edit Office"
                  onClick={() => {
                    handleEdit(params.row);
                  }}
                >
                  <FontAwesomeIcon icon={faEdit} size="sm" />
                </IconButton>
              )}

              <IconButton
                color="secondary"
                title="Emulate Office"
                onClick={() => {
                  handleEmulation(params.row);
                }}
              >
                <FontAwesomeIcon icon={faEye} size="sm" />
              </IconButton>
            </Box>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  return (
    <>
      <Card>
        {(isLoadingStore || isLoadingEmulation) && (
          <Loader type={isLoadingEmulation ? 'fullscreen' : 'overlay'} position="centered" />
        )}
        <CardTitle
          title="Offices"
          marginBottom={0}
          action={
            <>
              <Button
                onClick={onFilterToggle}
                startIcon={
                  <FontAwesomeIcon icon={isShowingFilters ? faFilterCircleXmark : faFilter} />
                }
                className={clsx('print--none')}
                color="secondary"
                size="small"
                disabled={isLoading}
              >
                Filters
              </Button>
            </>
          }
        />
        <CardFiltersLayout isOpen={isShowingFilters}>
          <FilterForm
            filters={filters}
            values={filterValues}
            filterLayouts={filtersLayout}
            defaultLayout={{ xs: 12 }}
            isLoading={isLoadingFilters}
            onSubmit={values => {
              if (Object.values(appliedFilters).length > 0) {
                onPageChange(0);
              }
              onSubmitFilters(values, isPoolService);
            }}
            onChange={onFiltersChange}
            isSubmitting={isLoading}
            onReset={values => {
              onReset(values, isPoolService);
            }}
          />
        </CardFiltersLayout>
        <ServerSideDataGrid
          autoHeight
          getRowId={(row: IOffice) => row.officeId}
          rows={rows}
          columns={columns}
          loading={isLoading}
          rowCount={recordCount}
          page={page}
          pageSize={perPage}
          sortModel={sortModel}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          onSortModelChange={onSortModelChange}
          hasMobileLayout
          mobileProps={{
            handleEdit: (val: IOffice) => {
              if (isPoolService) {
                history.push(
                  `${Paths.officeManagement.url}/${val.officeId}${
                    queryParams
                      ? `?redirect=${encodeURIComponent(
                          `${Paths.officeManagement.url}?${queryParams}`
                        )}`
                      : ''
                  }`
                );
              } else {
                handleEdit(val);
              }
            },
            handleView: (val: IOffice) => {
              handleEmulation(val);
            },
          }}
        />
      </Card>
      <EditStoreModal
        open={isShowingModal}
        onClose={() => {
          showModal(false);
          setCurrentOffice(null);
        }}
        currentOffice={currentOffice}
      />
    </>
  );
};
