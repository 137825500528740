import { FeatureFlag, Paths, Permissions } from '../../constants';
import { ReactElement } from 'react';
import { TaskTypesTable } from './task-types';
import { PaymentSetupsTable } from './payment-setups';
import { SalesTaxesTable } from './sales-taxes';
import { SalesTaxGroupsTable } from './sales-tax-groups';
import { BillingGroupsTable } from './billing-groups';
import { NewsManagementTable } from '../admin/news-management/news-management-table';
import { UserManagementDetails } from './user-mangement/user-management-details';
import { ReferralTypesTable } from './referral-types';
import { UserDefinedAccountTable } from './user-defined-account';
import { TerminationTypesTable } from './termination-types';
import { AddEditRatePage, RatesTable } from './rates';
import { TranCodesTable } from './tran-codes';
import { TranCodeGroupsTable } from './tran-code-groups';
import { UserDefinedSiteDefsTable } from './user-defined-site-defs';
import { ServiceLocationsList } from './service-locations/service-locations-list';
import { ServiceCodesTable } from './service-codes';
import { TreatmentTypesTable } from './treatment-types';
import { AccountClassDefsTable } from './account-class-defs';
import { AccountAlertDefsTable } from './account-alert-defs';
import { TechAvailability } from '../scheduling';
import { TechManagementDetails } from './tech-management/tech-management-details';
import {
  CommunicationTemplatesListPageDetails,
  CommunicationTemplatesDetailsPage,
} from './communication-templates';
import { MassSendPage } from './communication';
import { CommissionsSetupPage } from './commissions';
import { CompanyInfoTable } from './company-info';
import { QuickBooksPage } from './quickbooks';

export interface ISetupPageRoute {
  title: string;
  path: string;
  element: ReactElement;
  parent?: string;
  legacyUrl?: string;
  featureFlag?: string[] | string;
  permission?: string;
  permissions?: string[];
  isExternal?: boolean;
}

export const setupPageRoutes: ISetupPageRoute[] = [
  {
    title: Paths.newsManagementSetup.label,
    path: Paths.newsManagementSetup.url,
    element: <NewsManagementTable />,
    permission: Permissions.NewsManagement,
    parent: 'System Setup',
  },
  {
    title: Paths.companyInfo.label,
    path: Paths.companyInfo.url,
    element: <CompanyInfoTable />,
    legacyUrl: Paths.companyInfo.legacyUrl,
    featureFlag: FeatureFlag.v2Setup,
    permission: Permissions.Settings,
    parent: 'Company Setup',
  },
  {
    title: Paths.userManagement.label,
    path: Paths.userManagement.url,
    element: <UserManagementDetails />,
    legacyUrl: Paths.userManagement.legacyUrl,
    featureFlag: FeatureFlag.v2UserManagement,
    permission: Permissions.Settings,
    parent: 'User Setup',
  },
  {
    title: Paths.techManagement.label,
    path: Paths.techManagement.url,
    element: <TechManagementDetails />,
    featureFlag: [FeatureFlag.technicianManagement],
    permission: Permissions.TechMangement,
    parent: 'User Setup',
  },
  {
    title: Paths.setupTaskTypes.label,
    path: Paths.setupTaskTypes.url,
    element: <TaskTypesTable />,
    legacyUrl: Paths.setupTaskTypes.legacyUrl,
    featureFlag: FeatureFlag.v2Setup,
    permission: Permissions.Settings,
    parent: 'Accounts',
  },
  {
    title: Paths.paymentSetup.label,
    path: Paths.paymentSetup.url,
    element: <PaymentSetupsTable />,
    featureFlag: FeatureFlag.v2Setup,
    permission: Permissions.Settings,
    parent: 'Accounting',
  },
  {
    title: Paths.setupSalesTaxes.label,
    path: Paths.setupSalesTaxes.url,
    element: <SalesTaxesTable />,
    legacyUrl: Paths.setupSalesTaxes.legacyUrl,
    featureFlag: FeatureFlag.v2Setup,
    permission: Permissions.Settings,
    parent: 'Accounting',
  },
  {
    title: Paths.setupSalesTaxGroups.label,
    path: Paths.setupSalesTaxGroups.url,
    element: <SalesTaxGroupsTable />,
    legacyUrl: Paths.setupSalesTaxGroups.legacyUrl,
    featureFlag: FeatureFlag.v2Setup,
    permission: Permissions.Settings,
    parent: 'Accounting',
  },
  {
    title: Paths.billingGroups.label,
    path: Paths.billingGroups.url,
    element: <BillingGroupsTable />,
    featureFlag: FeatureFlag.v2Setup,
    permission: Permissions.Settings,
    parent: 'Accounting',
  },
  {
    title: Paths.referralTypes.label,
    path: Paths.referralTypes.url,
    element: <ReferralTypesTable />,
    featureFlag: FeatureFlag.v2Setup,
    permission: Permissions.Settings,
    parent: 'Sites',
  },
  {
    title: Paths.userDefinedAccount.label,
    path: Paths.userDefinedAccount.url,
    element: <UserDefinedAccountTable />,
    featureFlag: FeatureFlag.v2Setup,
    permission: Permissions.Settings,
    parent: 'Accounts',
  },
  {
    title: Paths.terminationTypes.label,
    path: Paths.terminationTypes.url,
    element: <TerminationTypesTable />,
    legacyUrl: Paths.terminationTypes.legacyUrl,
    featureFlag: FeatureFlag.v2Setup,
    permission: Permissions.Settings,
    parent: 'Accounts',
  },
  {
    title: Paths.tranCodes.label,
    path: Paths.tranCodes.url,
    element: <TranCodesTable />,
    legacyUrl: Paths.tranCodes.legacyUrl,
    featureFlag: FeatureFlag.v2Setup,
    permission: Permissions.Settings,
    parent: 'Accounting',
  },
  {
    title: Paths.rates.label,
    path: Paths.rates.url,
    element: <RatesTable />,
    legacyUrl: Paths.rates.legacyUrl,
    featureFlag: FeatureFlag.v2Setup,
    permission: Permissions.Settings,
    parent: 'Accounting',
  },
  {
    title: Paths.rates.label,
    path: `${Paths.rates.url}/:rateId`,
    element: <AddEditRatePage />,
    featureFlag: FeatureFlag.v2Setup,
    permission: Permissions.Settings,
  },
  {
    title: Paths.tranCodeGroups.label,
    path: Paths.tranCodeGroups.url,
    element: <TranCodeGroupsTable />,
    legacyUrl: Paths.tranCodeGroups.legacyUrl,
    featureFlag: FeatureFlag.v2Setup,
    permission: Permissions.Settings,
    parent: 'Accounting',
  },
  {
    title: Paths.quickbooks.label,
    path: Paths.quickbooks.url,
    element: <QuickBooksPage />,
    featureFlag: FeatureFlag.quickbooks,
    permission: Permissions.Settings,
    parent: 'Accounting',
  },
  {
    title: Paths.userDefinedSiteDefs.label,
    path: Paths.userDefinedSiteDefs.url,
    element: <UserDefinedSiteDefsTable />,
    legacyUrl: Paths.userDefinedSiteDefs.legacyUrl,
    featureFlag: FeatureFlag.v2Setup,
    permission: Permissions.Settings,
    parent: 'Sites',
  },
  {
    title: Paths.serviceLocations.label,
    path: Paths.serviceLocations.url,
    element: <ServiceLocationsList />,
    featureFlag: FeatureFlag.v2Setup,
    permission: Permissions.Settings,
    parent: 'Sites',
  },
  {
    title: Paths.serviceCodes.label,
    path: Paths.serviceCodes.url,
    element: <ServiceCodesTable />,
    legacyUrl: Paths.serviceCodes.legacyUrl,
    featureFlag: FeatureFlag.v2Setup,
    permission: Permissions.Settings,
    parent: 'Sites',
  },
  {
    title: Paths.treatmentTypes.label,
    path: Paths.treatmentTypes.url,
    element: <TreatmentTypesTable />,
    legacyUrl: Paths.treatmentTypes.legacyUrl,
    featureFlag: FeatureFlag.v2Setup,
    permission: Permissions.Settings,
    parent: 'Sites',
  },
  {
    title: Paths.commissionsSetup.label,
    path: Paths.commissionsSetup.url,
    element: <CommissionsSetupPage />,
    legacyUrl: Paths.commissionsSetup.legacyUrl,
    featureFlag: [FeatureFlag.v2Setup, FeatureFlag.v2Commissions],
    permission: Permissions.Settings,
    parent: 'User Setup',
  },
  {
    title: Paths.accountClassDefs.label,
    path: Paths.accountClassDefs.url,
    element: <AccountClassDefsTable />,
    featureFlag: FeatureFlag.v2Setup,
    permission: Permissions.Settings,
    parent: 'Accounts',
  },
  {
    title: Paths.accountAlertDefs.label,
    path: Paths.accountAlertDefs.url,
    element: <AccountAlertDefsTable />,
    featureFlag: FeatureFlag.v2Setup,
    permission: Permissions.Settings,
    parent: 'Accounts',
  },
  {
    title: Paths.techAvailability.label,
    path: Paths.techAvailability.url,
    element: <TechAvailability />,
    featureFlag: [FeatureFlag.technicianManagement],
    permission: Permissions.TechMangement,
    parent: 'Tech Management',
  },

  {
    title: Paths.techManagement.label,
    path: '/setup/tech-management',
    element: <TechManagementDetails />,
    featureFlag: [FeatureFlag.technicianManagement],
    permission: Permissions.Settings,
    parent: 'Tech Management',
  },
  {
    title: Paths.communicationTemplates.label,
    path: Paths.communicationTemplates.url,
    element: <CommunicationTemplatesListPageDetails />,
    legacyUrl: Paths.communicationTemplates.legacyUrl,
    permission: Permissions.Settings,
    parent: 'System Setup',
  },
  {
    title: Paths.communicationTemplates.label,
    path: `${Paths.communicationTemplates.url}/:communicationTemplateId`,
    element: <CommunicationTemplatesDetailsPage />,
    legacyUrl: Paths.communicationTemplates.legacyUrl,
    permission: Permissions.Settings,
  },
  {
    title: Paths.massSend.label,
    path: Paths.massSend.url,
    element: <MassSendPage />,
    permission: Permissions.Settings,
    parent: 'System Setup',
  },
];
