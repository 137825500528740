import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHouse,
  faPeopleGroup,
  faWrench,
  faList,
  faDollarSign,
  faRoute,
  faCalendar,
  faMoneyCheckDollar,
  faCalculator,
  faClipboard,
  faCreditCard,
  faFlask,
  faRepeat,
  faNewspaper,
  faCommentDots,
  faCircleQuestion,
  faChartArea,
  faEllipsis,
  faLock,
  faShop,
  faUser,
  faHeartPulse,
  faMailBulk,
  faCode,
  faSearchPlus,
  faMailReplyAll,
  faMessage,
  faWindowMaximize,
  faToolbox,
} from '@fortawesome/free-solid-svg-icons';
import { faRebel, faWpforms } from '@fortawesome/free-brands-svg-icons';
import { FeatureFlag } from './feature-flags';
import { Permissions } from './permissions';
import { ROLES } from './roles';
import { IRoute } from '../models';
import { styleGuideParentLink } from './style-guide-links';

export const REDIRECT_TO_PARAM_NAME = 'redirectTo';
const { REACT_APP_POOL_360_URL } = require('../buildSettings.json');

export const Paths = {
  home: {
    url: '/',
    label: 'Home',
    legacyUrl: undefined,
  },
  myAccount: {
    url: '/my-account',
    label: 'My Account',
    legacyUrl: undefined,
  },
  customers: {
    url: '/customers',
    label: 'Customers',
    legacyUrl: undefined,
  },
  customerView: {
    url: '/customer-view',
    label: 'Customer View',
    legacyUrl: undefined,
  },
  services: {
    url: '/services',
    label: 'Services',
    legacyUrl: undefined,
  },
  maintenance: {
    url: '/services/maintenance',
    label: 'Maintenance Services',
    legacyUrl: undefined,
  },
  ots: {
    url: '/services/ots',
    label: 'One-Time Services',
    legacyUrl: undefined,
  },
  repairs: {
    url: '/services/repairs',
    label: 'Repairs',
    legacyUrl: undefined,
  },
  fieldReport: {
    url: '/services/field-reports',
    label: 'Field Report',
    legacyUrl: undefined,
  },
  scheduling: {
    url: '/scheduling',
    label: 'Scheduling',
    legacyUrl: undefined,
  },
  tasks: {
    url: '/scheduling/tasks',
    label: 'Tasks',
    legacyUrl: undefined,
  },
  techAvailability: {
    url: '/setup/tech-availability',
    label: 'Tech Availability',
    legacyUrl: undefined,
  },
  techManagement: {
    url: '/setup/tech-management',
    label: 'Tech Management',
    legacyUrl: undefined,
  },
  routes: {
    url: '/routes',
    label: 'Routes',
    legacyUrl: undefined,
  },
  calendar: {
    url: '/scheduling/calendar',
    label: 'Calendar',
    legacyUrl: undefined,
  },
  reports: {
    url: `/reports`,
    label: 'Reports',
    legacyUrl: undefined,
  },
  billing: {
    url: '/billing',
    label: 'Billing',
    legacyUrl: undefined,
  },
  onlinePayments: {
    url: '/online-payments',
    label: 'Online Payments',
    legacyUrl: undefined,
  },
  commissions: {
    url: '/commissions',
    label: 'Commissions',
    legacyUrl: undefined,
  },
  inventory: {
    url: '/inventory',
    label: 'Inventory',
    legacyUrl: undefined,
  },
  payments: {
    url: '/payments',
    label: 'Payments',
    legacyUrl: undefined,
  },
  leads: {
    url: '/leads',
    label: 'Leads',
    legacyUrl: undefined,
  },
  estimates: {
    url: '/estimates',
    label: 'Estimates',
    legacyUrl: undefined,
  },
  search: {
    url: '/search',
    label: 'Search',
  },
  setup: {
    url: '',
    label: 'Setup',
    legacyUrl: undefined,
  },
  admin: {
    url: '',
    label: 'Admin',
    legacyUrl: undefined,
  },
  newsManagementAdmin: {
    url: '/admin/news-management',
    label: 'News Management',
    legacyUrl: undefined,
  },
  newsManagementSetup: {
    url: '/setup/news-management',
    label: 'News Management',
    legacyUrl: undefined,
  },
  userManagement: {
    url: '/setup/user-management',
    label: 'User/Roles Admin',
    legacyUrl: undefined,
  },
  communicationTemplates: {
    url: '/setup/communication-templates',
    label: 'Email/Text Templates',
    legacyUrl: undefined,
  },
  massSend: {
    url: '/setup/mass-send',
    label: 'Mass Send',
    legacyUrl: undefined,
  },
  setupTaskTypes: {
    url: '/setup/task-types',
    label: 'Task Types',
    legacyUrl: undefined,
  },
  commissionsSetup: {
    url: '/setup/commissions',
    label: 'Commissions',
    legacyUrl: undefined,
  },
  paymentSetup: {
    url: '/setup/payment-setup',
    label: 'Payment Setup',
    legacyUrl: undefined,
  },
  setupSalesTaxes: {
    url: '/setup/sales-taxes',
    label: 'Sales Taxes',
    legacyUrl: undefined,
  },
  setupRolePermissions: {
    url: '/setup/role-permissions',
    label: 'Role Permissions',
    legacyUrl: undefined,
  },
  setupSalesTaxGroups: {
    url: '/setup/sales-tax-groups',
    label: 'Sales Tax Groups',
    legacyUrl: undefined,
  },
  billingGroups: {
    url: '/setup/billing-groups',
    label: 'Billing Groups',
    legacyUrl: undefined,
  },
  terminationTypes: {
    url: '/setup/termination-types',
    label: 'Termination Types',
    legacyUrl: undefined,
  },
  companyInfo: {
    url: '/setup/company-info',
    label: 'Company Info',
    legacyUrl: undefined,
  },
  rates: {
    url: '/setup/rates',
    label: 'Rates',
    legacyUrl: undefined,
  },
  tranCodes: {
    url: '/setup/tran-codes',
    label: 'Transaction Codes',
    legacyUrl: undefined,
  },
  tranCodeGroups: {
    url: '/setup/tran-code-groups',
    label: 'Transaction Code Groups',
    legacyUrl: undefined,
  },
  quickbooks: {
    url: '/setup/quickbooks',
    label: 'QuickBooks',
    legacyUrl: undefined,
  },
  referralTypes: {
    url: '/setup/referral-types',
    label: 'Referral Types',
    legacyUrl: undefined,
  },
  userDefinedAccount: {
    url: '/setup/user-defined-account',
    label: 'Custom Account Info',
    legacyUrl: undefined,
  },
  userDefinedSiteDefs: {
    url: '/setup/user-defined-site-defs',
    label: 'Custom Site Info',
    legacyUrl: undefined,
  },
  serviceLocations: {
    url: '/setup/service-locations',
    label: 'Service Locations',
    legacyUrl: undefined,
  },
  serviceCodes: {
    url: '/setup/service-codes',
    label: 'Service Codes',
    legacyUrl: undefined,
  },
  treatmentTypes: {
    url: '/setup/treatment-types',
    label: 'Treatment Types',
    legacyUrl: undefined,
  },
  accountAlertDefs: {
    url: '/setup/account-alert-defs',
    label: 'Account Alerts',
    legacyUrl: undefined,
  },
  accountClassDefs: {
    url: '/setup/account-class-defs',
    label: 'Account Class',
    legacyUrl: undefined,
  },
  companySetup: {
    url: '',
    label: 'Company Setup',
    legacyUrl: undefined,
  },
  news: {
    url: '/news',
    label: "What's New",
    icon: faNewspaper,
    legacyUrl: undefined,
  },
  alerts: {
    url: '/alerts',
    label: 'Alerts',
    legacyUrl: undefined,
  },
  feedbackManagement: {
    url: '/admin/feedback-management',
    label: 'Feedback',
    legacyUrl: undefined,
  },
  feedback: {
    url: '/feedback',
    label: 'Feedback',
    icon: faCommentDots,
    legacyUrl: undefined,
  },
  help: {
    url: '/help',
    label: 'Help',
    icon: faCircleQuestion,
    legacyUrl: undefined,
  },
  officeManagement: {
    url: '/admin/office-management',
    label: 'Office Management',
    legacyUrl: undefined,
  },
  login: {
    url: '/login',
    label: 'Login',
    legacyUrl: undefined,
  },
  logout: {
    url: '/logout',
    label: 'Logout',
    legacyUrl: undefined,
  },
  forgotPassword: {
    url: '/forgot-password',
    label: 'Forgot Password',
    legacyUrl: undefined,
  },
  resetPassword: {
    url: '/reset-password',
    label: 'Reset Password',
    legacyUrl: undefined,
  },
  welcome: {
    url: '/welcome',
    label: 'Welcome',
    legacyUrl: undefined,
  },
  verifyAddresses: {
    url: '/verify-addresses',
    label: 'Verify Addresses',
    legacyUrl: undefined,
  },
  commissionRules: {
    url: '/setup/commission-rules',
    label: 'Commission Rules',
    legacyUrl: undefined,
  },
  pool360UserManagement: {
    url: `${REACT_APP_POOL_360_URL}/poolservice/users`,
    label: 'User Management',
    legacyUrl: undefined,
  },
  appSetup: {
    url: '/setup/news-management',
    label: 'Setup',
  },
  adminUsersManagement: {
    url: '/admin/users',
    label: 'Users',
    legacyUrl: undefined,
  },
  healthChecks: {
    url: '/admin/health-checks',
    label: 'Health Checks',
  },
  outboxQueue: {
    url: '/developer/outbox-messages',
    label: 'Outbox Messages',
  },
  searchIndex: {
    url: '/developer/search-index',
    label: 'Search Index',
  },
  queueEmails: {
    url: '/developer/queue-emails',
    label: 'Queue Emails',
  },
  sms: {
    url: '/developer/sms',
    label: 'SMS Messages',
  },
  authentication: {
    url: '/developer/authentication',
    label: 'Authentication',
  },
  azureFunctions: {
    url: '/developer/azure-functions',
    label: 'Azure Functions',
  },
  developerOts: {
    url: '/developer/ots',
    label: 'One-time Services',
  },
  developerTechs: {
    url: '/developer/techs',
    label: 'Technicians',
  },
  unauthorized: {
    url: '/unauthorized',
  },
};

let routeIndex = 0;

export const mainRoutes: IRoute[] = [
  {
    id: routeIndex++,
    label: Paths.home.label,
    link: Paths.home.url,
    icon: <FontAwesomeIcon icon={faHouse} />,
    featureFlags: [FeatureFlag.v2HomePage],
    subNav: null,
    roles: [ROLES.Office, ROLES.Emulating],
  },
  {
    id: routeIndex++,
    label: Paths.customers.label,
    link: Paths.customers.url,
    icon: <FontAwesomeIcon icon={faPeopleGroup} />,
    featureFlags: [FeatureFlag.v2Customers],
    subNav: null,
    permissions: [Permissions.ViewCustomers],
    roles: [ROLES.Office, ROLES.Emulating],
  },
  {
    id: routeIndex++,
    label: Paths.maintenance.label,
    link: Paths.maintenance.url,
    icon: <FontAwesomeIcon icon={faRepeat} />,
    featureFlags: [FeatureFlag.v2Services],
    permissions: [Permissions.ViewScheduledServices],
    isFeatureFlagOn: true,
    roles: [ROLES.Office, ROLES.Emulating],
  },
  {
    id: routeIndex++,
    label: Paths.ots.label,
    link: Paths.ots.url,
    icon: <FontAwesomeIcon icon={faWrench} />,
    featureFlags: [FeatureFlag.v2Ots],
    isFeatureFlagOn: true,
    permissions: [Permissions.ViewRepairs],
    roles: [ROLES.Office, ROLES.Emulating],
  },
  {
    id: routeIndex++,
    label: Paths.routes.label,
    link: Paths.routes.url,
    icon: <FontAwesomeIcon icon={faRoute} />,
    featureFlags: [FeatureFlag.v2Routes],
    subNav: null,
    permissions: [Permissions.ViewRoutes],
    roles: [ROLES.Office, ROLES.Emulating],
  },
  {
    id: routeIndex++,
    label: Paths.calendar.label,
    link: Paths.calendar.url,
    icon: <FontAwesomeIcon icon={faCalendar} />,
    featureFlags: [FeatureFlag.v2Scheduling],
    permissions: [Permissions.ViewScheduledServices],
    isFeatureFlagOn: true,
    roles: [ROLES.Office, ROLES.Emulating],
  },
  {
    id: routeIndex++,
    label: Paths.fieldReport.label,
    link: Paths.fieldReport.url,
    icon: <FontAwesomeIcon icon={faFlask} />,
    featureFlags: [FeatureFlag.v2FieldReport],
    permissions: [Permissions.ViewPayments],
    isFeatureFlagOn: true,
    roles: [ROLES.Office, ROLES.Emulating],
  },
  {
    id: routeIndex++,
    label: Paths.tasks.label,
    link: Paths.tasks.url,
    icon: <FontAwesomeIcon icon={faList} />,
    featureFlags: [FeatureFlag.v2Scheduling],
    permissions: [Permissions.ViewScheduledServices],
    isFeatureFlagOn: true,
    roles: [ROLES.Office, ROLES.Emulating],
  },
  {
    id: routeIndex++,
    label: Paths.reports.label,
    link: Paths.reports.url,
    icon: <FontAwesomeIcon icon={faChartArea} />,
    featureFlags: [FeatureFlag.v2Reports],
    permissions: [Permissions.ViewReports],
    roles: [ROLES.Office, ROLES.Emulating],
  },
  {
    id: routeIndex++,
    label: Paths.billing.label,
    link: Paths.billing.url,
    icon: <FontAwesomeIcon icon={faDollarSign} />,
    featureFlags: [FeatureFlag.v2Billing],
    subNav: null,
    permissions: [Permissions.ViewBilling],
    roles: [ROLES.Office, ROLES.Emulating],
  },
  {
    id: routeIndex++,
    label: Paths.leads.label,
    link: Paths.leads.url,
    icon: <FontAwesomeIcon icon={faWpforms} />,
    featureFlags: [FeatureFlag.v2Leads],
    isFeatureFlagOn: true,
    roles: [ROLES.Office, ROLES.Emulating],
  },
  {
    id: routeIndex++,
    label: 'More',
    link: '',
    legacyUrl: '',
    icon: <FontAwesomeIcon icon={faEllipsis} />,
    roles: [ROLES.Office, ROLES.Emulating],
    subNav: [
      {
        id: routeIndex++,
        label: Paths.estimates.label,
        link: Paths.estimates.url,
        featureFlagCheck: true,
        icon: <FontAwesomeIcon icon={faCalculator} />,
        featureFlags: [FeatureFlag.v2Ots],
        permissions: [Permissions.ViewEstimates],
      },
      {
        id: routeIndex++,
        label: Paths.inventory.label,
        link: Paths.inventory.url,
        featureFlagCheck: true,
        icon: <FontAwesomeIcon icon={faClipboard} />,
        featureFlags: [FeatureFlag.v2InventoryReport],
        permissions: [Permissions.Inventory],
      },
      {
        id: routeIndex++,
        label: Paths.commissions.label,
        link: Paths.commissions.url,
        featureFlagCheck: true,
        icon: <FontAwesomeIcon icon={faMoneyCheckDollar} />,
        featureFlags: [FeatureFlag.v2Commissions],
        permissions: [Permissions.EditViewCommissions],
      },
      {
        id: routeIndex++,
        label: Paths.payments.label,
        link: Paths.payments.url,
        featureFlagCheck: true,
        icon: <FontAwesomeIcon icon={faCreditCard} />,
        featureFlags: [FeatureFlag.v2Payments],
        permissions: [Permissions.ViewPayments],
      },
    ],
  },
  {
    id: routeIndex++,
    label: 'Setup',
    link: Paths.appSetup.url,
    legacyUrl: '',
    icon: <FontAwesomeIcon icon={faLock} />,
    featureFlags: [FeatureFlag.v2Setup],
    roles: [ROLES.Office, ROLES.Emulating],
    permissions: [Permissions.Settings, Permissions.NewsManagement, Permissions.TechMangement],
  },
  {
    id: routeIndex++,
    label: 'Admin',
    link: '',
    legacyUrl: '',
    icon: <FontAwesomeIcon icon={faLock} />,
    roles: [ROLES.SuperAdmin, ROLES.Emulating],
    subNav: [
      {
        id: routeIndex++,
        label: Paths.officeManagement.label,
        link: Paths.officeManagement.url,
        icon: <FontAwesomeIcon icon={faShop} />,
        roles: [ROLES.SuperAdmin, ROLES.Emulating],
      },
      {
        id: routeIndex++,
        label: Paths.newsManagementAdmin.label,
        link: Paths.newsManagementAdmin.url,
        icon: <FontAwesomeIcon icon={faNewspaper} />,
        roles: [ROLES.SuperAdmin, ROLES.Emulating],
      },
      {
        id: routeIndex++,
        label: Paths.feedbackManagement.label,
        link: Paths.feedbackManagement.url,
        icon: <FontAwesomeIcon icon={faCommentDots} />,
        roles: [ROLES.SuperAdmin, ROLES.Emulating],
      },
      {
        id: routeIndex++,
        label: Paths.adminUsersManagement.label,
        link: Paths.adminUsersManagement.url,
        icon: <FontAwesomeIcon icon={faUser} />,
        roles: [ROLES.SuperAdmin, ROLES.Emulating],
      },
      {
        id: routeIndex++,
        label: Paths.healthChecks.label,
        link: Paths.healthChecks.url,
        icon: <FontAwesomeIcon icon={faHeartPulse} />,
        roles: [ROLES.SuperAdmin, ROLES.Emulating],
      },
      styleGuideParentLink,
    ],
  },
  {
    id: routeIndex++,
    label: 'Developer',
    link: '',
    legacyUrl: '',
    icon: <FontAwesomeIcon icon={faCode} />,
    roles: [ROLES.SuperAdmin, ROLES.Emulating, ROLES.Developer],
    subNav: [
      {
        id: routeIndex++,
        label: Paths.authentication.label,
        link: Paths.authentication.url,
        icon: <FontAwesomeIcon icon={faLock} />,
        roles: [ROLES.SuperAdmin, ROLES.Emulating, ROLES.Developer],
      },
      {
        id: routeIndex++,
        label: Paths.azureFunctions.label,
        link: Paths.azureFunctions.url,
        icon: <FontAwesomeIcon icon={faWindowMaximize} />,
        roles: [ROLES.SuperAdmin, ROLES.Emulating, ROLES.Developer],
      },
      {
        id: routeIndex++,
        label: Paths.developerOts.label,
        link: Paths.developerOts.url,
        icon: <FontAwesomeIcon icon={faRebel} />,
        roles: [ROLES.SuperAdmin, ROLES.Emulating, ROLES.Developer],
      },
      {
        id: routeIndex++,
        label: Paths.outboxQueue.label,
        link: Paths.outboxQueue.url,
        icon: <FontAwesomeIcon icon={faMailBulk} />,
        roles: [ROLES.SuperAdmin, ROLES.Emulating, ROLES.Developer],
      },
      {
        id: routeIndex++,
        label: Paths.queueEmails.label,
        link: Paths.queueEmails.url,
        icon: <FontAwesomeIcon icon={faMailReplyAll} />,
        roles: [ROLES.SuperAdmin, ROLES.Emulating, ROLES.Developer],
      },
      {
        id: routeIndex++,
        label: Paths.searchIndex.label,
        link: Paths.searchIndex.url,
        icon: <FontAwesomeIcon icon={faSearchPlus} />,
        roles: [ROLES.SuperAdmin, ROLES.Emulating, ROLES.Developer],
      },
      {
        id: routeIndex++,
        label: Paths.sms.label,
        link: Paths.sms.url,
        icon: <FontAwesomeIcon icon={faMessage} />,
        roles: [ROLES.SuperAdmin, ROLES.Emulating, ROLES.Developer],
      },
      {
        id: routeIndex++,
        label: Paths.developerTechs.label,
        link: Paths.developerTechs.url,
        icon: <FontAwesomeIcon icon={faToolbox} />,
        roles: [ROLES.SuperAdmin, ROLES.Emulating, ROLES.Developer],
      },
    ],
  },
];
