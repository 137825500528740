import { FC } from 'react';
import { styled } from '@mui/material';
import { Card } from '../../components';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { ISimpleDateRange } from '../../models';

interface IHomeCalendarProps {
  dateRange: ISimpleDateRange;
  handleDateChange: (value: ISimpleDateRange) => void;
}

export const HomeCalendar: FC<IHomeCalendarProps> = ({ dateRange, handleDateChange }) => {
  return (
    <StyledCard sxProps={{ height: '100%', paddingBottom: 0 }}>
      <DateCalendar
        value={dateRange.selection.startDate}
        views={['year', 'month', 'day']}
        onChange={(newValue: Date) => {
          handleDateChange({
            selection: {
              key: 'selection',
              startDate: newValue,
              endDate: newValue,
            },
          });
        }}
        sx={{
          height: '323px',
          width: '100%',
        }}
        classes={{
          root: classes.calendarRoot,
          viewTransitionContainer: classes.calendarContainer,
        }}
        data-testid="home-calendar"
      />
    </StyledCard>
  );
};

const PREFIX = 'HomeCalendar';

const classes = {
  calendarRoot: `${PREFIX}-calendarRoot`,
  calendarContainer: `${PREFIX}-calendarContainer`,
};

const StyledCard = styled(Card)(({ theme }) => ({
  [`& .${classes.calendarRoot}`]: {
    width: '100%',
    '& .Mui-selected, & .Mui-selected:focus, & .Mui-selected:hover': {
      color: `${theme.palette.common.white} !important`,
    },
  },

  [`& .${classes.calendarContainer}`]: {
    '& > div > div': {
      justifyContent: 'space-between !important',
      paddingLeft: theme.spacing(1.25),
      paddingRight: theme.spacing(1.25),
    },
    '& div[role=row]': {
      paddingLeft: theme.spacing(1.25),
      paddingRight: theme.spacing(1.25),
      justifyContent: 'space-between !important',
    },
  },
}));
