import React, { createContext, FC, useEffect, useState, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  getMe,
  getTimeZone,
  getOffice,
  getOfficePaymentProcessor,
  getAccountingProvider,
  getAdyenDetails,
} from '../fetch';
import {
  AdyenVerificationStatus,
  IAccountingProvider,
  IAdyenDetails,
  IOfficeDetail,
  IPaymentProcessorModel,
  IUpdateUser,
  IUser,
  PaymentProcessor,
  EAccountProviderEntryType,
  EAccountProvider,
} from '../models';
import { useIsAuthenticated } from '@azure/msal-react';
// @ts-ignore
import { msalInstance } from '../services';
import { useQuery } from 'react-query';
import { userSignal } from '../signals';
import { getLegacyUrl, OFFICE_EMULATE_DATA } from '../helpers';
import { Paths, ROLES } from '../constants';
import { Loader } from '../components';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface IUserContext {
  setUser: (user: IUser | undefined) => void;
  user: IUser | undefined;
  isFetching: boolean;
  loadUser: (shouldLoad?: boolean) => Promise<void>;
  technicianId: string | null;
  isSuperAdmin: boolean;
  isOfficeAdmin: boolean;
  isServiceTech: boolean;
  isStoreAdmin: boolean;
  userAvatarUrl?: string;
  updateCurrentUser?: (updateData: IUpdateUser) => Promise<void>;
  updateCurrentUserAvatar?: (newAvatarFile: File) => Promise<void>;
  permissions?: string[];
  timezone: string;
  office?: IOfficeDetail;
  paymentProcessor?: IPaymentProcessorModel;
  updatePaymentProcessorData: () => Promise<void>;
  accountingProvider?: IAccountingProvider;
  reloadAccountingProvider?: any;
  isLoadingAccountProvider?: boolean;
  isLoadingOffice?: boolean;
  adyenOnboardingHasProblems?: boolean;
  adyenDetails?: IAdyenDetails;
  setAdyenDetails?: (val: IAdyenDetails | undefined) => void;
  isFetchingAdyenDetails?: boolean;
  setIsFetchingAdyenDetails?: (val: boolean) => void;
  hasQBInvoiceEntryType?: boolean;
}

interface IUserContextHandlerProps {
  children: React.ReactNode;
}

export const UserContext = createContext<IUserContext>({
  user: undefined,
  isFetching: false,
  loadUser: () => Promise.resolve(),
  setUser: () => {},
  technicianId: null,
  isSuperAdmin: false,
  isOfficeAdmin: false,
  isServiceTech: false,
  isStoreAdmin: false,
  updateCurrentUser: () => Promise.resolve(),
  updateCurrentUserAvatar: () => Promise.resolve(),
  permissions: [],
  timezone: '',
  office: undefined,
  paymentProcessor: undefined,
  updatePaymentProcessorData: () => Promise.resolve(),
  accountingProvider: undefined,
  reloadAccountingProvider: () => Promise.resolve(),
  isLoadingAccountProvider: false,
  isLoadingOffice: false,
  adyenOnboardingHasProblems: false,
  adyenDetails: undefined,
  setAdyenDetails: () => {},
  isFetchingAdyenDetails: false,
  setIsFetchingAdyenDetails: () => {},
  hasQBInvoiceEntryType: false,
});

export const UserContextHandler: FC<IUserContextHandlerProps> = ({ children }) => {
  const [user, setUser] = useState<IUser | undefined>(undefined);
  const [isFetching, setFetching] = useState<boolean>(true);
  const [timezone, setTimezone] = useState('');
  const [hasError, setHasError] = useState(false);
  const [adyenDetails, setAdyenDetails] = useState<IAdyenDetails | undefined>(undefined);
  const [isLoadingAdyenDetails, setIsLoadingAdyenDetails] = useState(false);
  const flags = useFlags();

  const location = useLocation();
  const isEntraAuthenticated = useIsAuthenticated() ?? false;
  const history = useHistory();

  const handleSetUser = (user: IUser | undefined) => {
    userSignal.value = user;
    setUser(user);
  };

  const loadUser = async (shouldLoad?: boolean) => {
    try {
      if (shouldLoad) {
        setFetching(true);
      }
      const me = await getMe();
      const emulatingData = !!sessionStorage.getItem(OFFICE_EMULATE_DATA)
        ? JSON.parse(sessionStorage.getItem(OFFICE_EMULATE_DATA)!)
        : null;
      if (
        me.userId === emulatingData?.userId &&
        me.userType === ROLES.SuperAdmin &&
        emulatingData?.isPoolService
      ) {
        me.officeId = emulatingData?.officeId;
        me.officeCode = emulatingData?.code;
        me.officeName = emulatingData?.officeName;
        me.userType = ROLES.Emulating;
      }
      const user = {
        ...me,
        isEntraId: true,
      };
      handleSetUser(user);
      // legacy admin user login, then redirect legacy /admin/office/list
      if (user && !user?.userType && user?.loginType === ROLES.Administrator) {
        window.location.href = `${getLegacyUrl()}/Admin/Office/List`;
        return;
      }
      if (
        user.userType === ROLES.SuperAdmin &&
        !emulatingData &&
        !location.pathname.includes('admin')
      ) {
        history.push(Paths.officeManagement.url);
      }
    } catch (error) {
      setHasError(true);
    } finally {
      setFetching(false);
    }
  };

  const { data: officeData, isFetching: isLoadingOffice } = useQuery<IOfficeDetail, Error>(
    ['getOffice', user?.officeId],
    () => getOffice(user?.officeId!),
    {
      enabled: !!user?.officeId,
    }
  );

  const { data: paymentProcessorData, refetch: updatePaymentProcessorData } = useQuery<
    IPaymentProcessorModel,
    Error
  >(
    ['getOfficePaymentProcessor', user?.officeId],
    () => getOfficePaymentProcessor(user?.officeId!),
    {
      enabled: !!user?.officeId,
    }
  );

  const fetchAdyenDetails = async () => {
    try {
      setIsLoadingAdyenDetails(true);
      let res = await getAdyenDetails(user?.officeId ?? officeData?.officeId!);
      setAdyenDetails(res);
    } catch (error) {
      console.error('Error fetching Adyen details:', error);
    } finally {
      setIsLoadingAdyenDetails(false);
    }
  };

  useEffect(() => {
    if (paymentProcessorData?.paymentProcessor === PaymentProcessor.Adyen && flags.adyen) {
      fetchAdyenDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.officeId, paymentProcessorData, officeData, flags.adyen]);

  const {
    data: accountingProviderData,
    refetch: reloadAccountingProvider,
    isLoading: isLoadingAccountingProvider,
    isFetching: isFetchingAccountingProvider,
  } = useQuery<IAccountingProvider, Error>(
    ['getAccountingProvider', officeData?.officeId],
    () => getAccountingProvider(officeData?.officeId!),
    {
      enabled:
        !!officeData?.officeId &&
        // needs to be a store admin to see the accounting provider
        user?.userGroups?.filter(v => v.userGroupName.includes('ServiceTech'))?.length === 0,
    }
  );

  const handleUpdatePaymentProcessorData = async () => {
    await updatePaymentProcessorData();
  };
  // this effect is used to initially called to load the user from /me endpoint for pool service
  useEffect(() => {
    if (
      msalInstance.getActiveAccount() &&
      isEntraAuthenticated &&
      !location.pathname.includes('/external/')
    ) {
      loadUser(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [msalInstance, isEntraAuthenticated]);

  useEffect(() => {
    const fetchTimeZone = async () => {
      const timezone = await getTimeZone(user?.officeId!);
      setTimezone(timezone);
    };
    if (user && user?.officeId) {
      fetchTimeZone();
    }
  }, [user]);

  const technicianId = useMemo(() => {
    if (user?.userType === ROLES.Office) {
      return user?.userId;
    } else {
      return null;
    }
  }, [user]);
  const isSuperAdmin =
    (user?.userType === ROLES.SuperAdmin || user?.userType === ROLES.Emulating) &&
    user?.isOfficeAdmin
      ? true
      : false;
  const isOfficeAdmin =
    (user?.userGroups &&
      user?.userGroups?.filter(v => v.userGroupName.includes('StoreAdmin'))?.length > 0) ??
    false;
  const isServiceTech =
    (user?.userGroups &&
      user?.userGroups?.filter(v => v.userGroupName.includes('ServiceTech'))?.length > 0) ??
    false;
  const permissions = useMemo(() => user?.roles?.map(r => r.name) ?? [], [user]);
  const office = useMemo(() => officeData ?? undefined, [officeData]);
  const paymentProcessor = useMemo(() => paymentProcessorData, [paymentProcessorData]);
  const isStoreAdmin = user?.userGroups.some(v => v.userGroupName === 'StoreAdmin') ? true : false;
  const accountingProvider = useMemo(() => accountingProviderData, [accountingProviderData]);

  const hasQBInvoiceEntryType = useMemo(
    () =>
      accountingProvider?.provider === EAccountProvider.QuickBooks &&
      accountingProvider?.entryType === EAccountProviderEntryType.Invoice &&
      flags.quickbooksInvoicing,
    [accountingProvider, flags.quickbooksInvoicing]
  );

  const adyenOnboardingHasProblems = useMemo(() => {
    const adyenErrors = adyenDetails?.data.filter(
      v => v.verificationStatus === AdyenVerificationStatus.Invalid
    );
    if (
      adyenDetails &&
      paymentProcessor?.paymentProcessor === PaymentProcessor.Adyen &&
      adyenErrors &&
      adyenErrors?.length > 0
    ) {
      return true;
    }
    return false;
  }, [adyenDetails, paymentProcessor?.paymentProcessor]);

  if (!user && !hasError) {
    return <Loader position="centered" type="fullscreen" />;
  }

  return (
    <UserContext.Provider
      value={{
        user,
        isFetching,
        loadUser,
        setUser: handleSetUser,
        technicianId,
        isSuperAdmin,
        isOfficeAdmin,
        isServiceTech,
        isStoreAdmin,
        permissions,
        timezone,
        office,
        paymentProcessor,
        updatePaymentProcessorData: handleUpdatePaymentProcessorData,
        accountingProvider,
        reloadAccountingProvider,
        isLoadingAccountProvider: isLoadingAccountingProvider || isFetchingAccountingProvider,
        isLoadingOffice,
        adyenOnboardingHasProblems,
        adyenDetails,
        setAdyenDetails: val => setAdyenDetails(val),
        isFetchingAdyenDetails: isLoadingAdyenDetails,
        setIsFetchingAdyenDetails: val => setIsLoadingAdyenDetails(val),
        hasQBInvoiceEntryType,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
