import { FC } from 'react';
import { Box, Button, SxProps, useMediaQuery } from '@mui/material';
import { SaveButton } from '../button';
import { IconDefinition, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CancelIcon } from '../icon';

interface IModalSaveSection {
  handleCancel: () => void;
  isSaveDisabled?: boolean;
  isCancelDisabled?: boolean;
  handleSave?: (e: any) => void;
  submitLabel?: string;
  submitIcon?: IconDefinition;
  hasNoMarginTop?: boolean;
  showBack?: boolean;
  cancelLabel?: string;
  children?: React.ReactNode;
  fullWidth?: boolean;
  customIcon?: any;
  containerStyles?: SxProps;
}

export const ModalSaveSection: FC<IModalSaveSection> = ({
  handleCancel,
  isSaveDisabled,
  handleSave,
  submitLabel,
  submitIcon,
  hasNoMarginTop,
  showBack,
  cancelLabel,
  isCancelDisabled,
  children,
  fullWidth,
  customIcon,
  containerStyles,
}) => {
  const isMobile = useMediaQuery(`(max-width: 567px)`);
  return (
    <Box
      marginTop={hasNoMarginTop ? 0 : '1rem'}
      display="flex"
      gap={1}
      flexDirection={isMobile ? 'column' : 'row'}
      justifyContent="flex-end"
      width={fullWidth ? '100%' : 'auto'}
      sx={containerStyles}
    >
      <Button
        type="button"
        color="inherit"
        onClick={handleCancel}
        startIcon={showBack ? <FontAwesomeIcon icon={faChevronLeft} /> : <CancelIcon />}
        disabled={isCancelDisabled}
        data-testId="cancel-button"
      >
        {cancelLabel ? cancelLabel : showBack ? 'Back' : 'Cancel'}
      </Button>
      <SaveButton
        disabled={isSaveDisabled}
        handleSave={handleSave}
        text={submitLabel}
        customIcon={customIcon}
        icon={submitIcon}
      />
      {children}
    </Box>
  );
};
