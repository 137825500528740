import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ExternalLink, Link } from '../link';
import { ListItemIcon, ListItemText, MenuItem, styled } from '@mui/material';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface IUserMenuItem {
  to?: string;
  icon: IconProp;
  text: string;
  isExternal?: boolean;
  isVisible?: boolean;
  handleMenuClick?: () => void;
}

export const UserMenuItem: FC<IUserMenuItem> = ({
  to,
  icon,
  text,
  isExternal = true,
  isVisible = true,
  handleMenuClick,
  ...props
}) => {
  const getChildren = () => (
    <>
      <ListItemIcon>
        <FontAwesomeIcon icon={icon} />
      </ListItemIcon>
      <ListItemText>{text}</ListItemText>
    </>
  );
  if (!isVisible) {
    return null;
  }
  if (isExternal && to) {
    return (
      <MenuItem sx={{ minHeight: 'auto' }} {...props}>
        <StyledExternalLink to={to}>{getChildren()}</StyledExternalLink>
      </MenuItem>
    );
  } else {
    if (to) {
      <MenuItem onClick={handleMenuClick} sx={{ minHeight: 'auto' }} {...props}>
        <StyledLink to={to}>{getChildren()}</StyledLink>
      </MenuItem>;
    }
    return (
      <MenuItem onClick={handleMenuClick} sx={{ minHeight: 'auto' }} {...props}>
        {getChildren()}
      </MenuItem>
    );
  }
};

const LinkStyles = (theme: any) => {
  return {
    display: 'flex',
    alignItems: 'center',
    [`& svg`]: {
      color: theme.palette.primary.main,
    },
  };
};

const StyledExternalLink = styled(ExternalLink)(({ theme }) => {
  return LinkStyles(theme);
});

const StyledLink = styled(Link)(({ theme }) => {
  return LinkStyles(theme);
});
